<template>
  <v-row justify="center" align="center" id="SobreMi">
    <v-col cols="12" md="6" class="text-center white--text pb-0">
      <h2 style="opacity: 0">Sobre Mí</h2>
    </v-col>
    <v-col cols="12" md="6" class="white--text px-16">
      <h3 class="my-4 oculto">Bienvenidos a mi portafolio.</h3>
      <p class="oculto">
        Soy José Bernardo Bernal Cabrera, Ingeniero en Informática por la
        Universidad Autónoma de San Luis Potosí y con más de 3 años de
        experiencia en desarrollo web.
      </p>
      <p class="oculto">
        Soy una persona que le gusta aprender por si misma, por lo que busco
        estar actualizado en lo que a nuevas tecnologías se refiere. Cada
        proyecto que realizo, es una oportunidad para aprender más así como para
        crecer en mi ámbito profesional.
      </p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  mounted: function() {
    this.apareceScroll();
  },
  methods: {
    apareceScroll: function() {
      document.addEventListener("scroll", this.animaciones);
      document.addEventListener("wheel", this.animaciones);
      document.addEventListener("touchmove", this.animaciones);
    },
    animaciones: function() {
      var cuerpo = document.querySelector("html");
      var elementos = this.$el.getElementsByClassName("oculto");
      var blocktitle = this.$el.querySelector("h2");
      var topVent = cuerpo.scrollTop;
      for (let i = 0; i < elementos.length; i++) {
        var topElement = elementos[i].offsetTop;
        if (
          topVent > topElement - 500 ||
          topVent > blocktitle.offsetTop - 500
        ) {
          blocktitle.classList.add("bottomToTop");
          elementos[i].classList.add("derechaIzquierda");
        }
      }
    }
  }
};
</script>
<style scoped>
.row {
  background-color: #0a0f0d;
}
</style>
